import AttachmentsView from "features/attachments/AttachmentsView";
import { AttachmentEntityTypes } from "features/attachments/attachmentsConsts";
import { ReservationDocumentsStyle } from "./ReservationDocumentsStyle";

const ReservationDocuments = ({ reservation }) => {
    const classes = ReservationDocumentsStyle();


    return (
        <>
            <AttachmentsView
                entityId={reservation?.id}
                entityType={AttachmentEntityTypes.RESERVATION}
                label='Document'
            />
        </>
    )
};

export default ReservationDocuments;
