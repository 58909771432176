import { Button, Grid } from "@material-ui/core";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetVehicleByReservationIdQuery } from "../../../reservationSlice";
import { IconCar } from "icons";
import { ReservationVehicleStyles } from "./ReservationVehicleStyles";
import { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { ReservationVehicleList } from "./ReservationVehicleList";
import { AddVehicle } from "./AddVehicle";
import CopyToClipboard from "components/CopyToClipboard";
import ExportButton from "components/ExportButton/ExportButton";

const ReservationVehicle = ({ reservation }) => {
  const classes = ReservationVehicleStyles();
  const [add, setAdd] = useState(false);

  let {
    data: vehicles,
    error: vehiclesError,
    isLoading: isLoadingvehicles,
  } = useGetVehicleByReservationIdQuery(reservation?.id);

  const mapExportData = (row) => {
    const { vin, assetId, ...others } = row;
    return {
      vin,
      assetId
    }
  }

  return (
    <Grid container direction="column" spacing={2}>
      <LoadingSpinner loading={isLoadingvehicles} />
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="center" justifyContent="end">
          {/* <Grid item container display="felx" alignItems="center" xs={10}>
            <Grid item className={classes.title}>
              Reservation Vehicles
            </Grid>
          </Grid> */}
          <Grid item container justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item><Button
              variant="outlined"
              onClick={() => setAdd(true)}
              startIcon={<AddIcon />}
            >
              {"Add vehicle"}
            </Button></Grid>
            {!!vehicles?.length && <Grid item><CopyToClipboard buttonComponent={<Button
              variant="outlined"
            >
              Copy VINs
            </Button>} text={vehicles?.map(v => v.vin)?.join(", ")} title="Copy VINs to Clipboard" /></Grid>}

            <Grid item><ExportButton label="Export" btnStyle="outlined" icon={null} mapExportData={mapExportData} data={vehicles} /></Grid>
          </Grid>
        </Grid>
      </Grid>
      {add ? (
        <AddVehicle
          setAdd={setAdd}
          client={reservation?.clientId}
          reservationId={reservation?.id}
        ></AddVehicle>
      ) : (
        <></>
      )}

      <ReservationVehicleList
        vehiclesList={vehicles}
        reservationId={reservation?.id}
      ></ReservationVehicleList>

    </Grid>
  );
};

export default ReservationVehicle;
