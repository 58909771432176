import { Grid, IconButton } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { EntityAuditTooltip } from 'components/EntityAudit/EntityAuditTooltip';
import NotificationProvider from 'components/NotificationProvider';
import usePrompt from 'hooks/usePrompt';
import { IconDeleteBlue, IconEdit } from 'icons';
import { useState } from 'react';
import NoteEditor from './NoteEditor';
import NoteView from './NoteView';
import { useDeleteNoteMutation } from './notesSlice';
import { NotesStyle } from './style';

const Note = ({ note, readOnly= false }) => {
    const classes = NotesStyle();
    const [deleteNote, { isLoading: isDeleting }] = useDeleteNoteMutation();

    const [edit, setEdit] = useState(false);

    const { triggerPrompt } = usePrompt();

    const onDelete = async () => {
        triggerPrompt({
            title: "Delete Note",
            content: "Are you sure you want to delete this note?",
            onConfirm: async () => {
                let res = await deleteNote(note);

                if (!res?.error) {
                    NotificationProvider.success('Deleted successfully');
                } else {
                    NotificationProvider.error('Failed to delete');
                }
            },
        });
    };

    return (
        <Grid className={classes.noteWrapper} container justifyContent='space-between' alignItems='center'>
            <Grid item>
                {edit && <NoteEditor note={note} onClose={() => setEdit(false)} />}
                <NoteView note={note} />
            </Grid>
                <Grid >
                    {!readOnly && 
                    <Authorize profile={permissionProfiles.NOTES.EDIT_NOTES}>
                        <IconButton size='small' className={`${classes.iconButton} ${classes.noteActions}`} onClick={() => setEdit(true)}>
                            <IconEdit />
                        </IconButton>
                        <Authorize profile={permissionProfiles.NOTES.DELETE}>
                            <IconButton disabled={isDeleting} size='small' className={`${classes.iconButton} ${classes.noteActions}`} onClick={onDelete}>
                                <IconDeleteBlue width={"18"} height={'18'} />
                            </IconButton>
                        </Authorize>
                    </Authorize>}
                    <EntityAuditTooltip entity={note} />
                </Grid>
        </Grid>
    );
}
export default Note;