import React, { useEffect, useMemo, useState } from "react";
import { useGetAllReservationsQuery } from "./reservationSlice";
import PageComponent from "components/Page/PageComponent";
import DataTable from "components/DataTable/DataTable";
import { userPreferencesComponentIds } from "components/UserPreferences/userPreferencesConsts";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters, setFilters, setSearchVal } from "./reservationsFiltersSlice";
import FilterService, { fieldTypes } from "services/filterService";
import useSortOptions from "hooks/useSortOptions";
import { Grid } from "@material-ui/core";
import { orderBy } from "lodash";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { AddReservation } from "./NewReservation/AddReservation";
import { Link } from "@material-ui/core";
import { reservationStatusList } from "./reservationConsts";
import { arrayToObject } from "utils/object-util";
import { ReservationOverviewAction } from "./NewReservation/ReservationOverviewAction";
import { ReservationDocumentsAction } from "./NewReservation/ReservationDocumentsAction";
import { ReservationVehicleAction } from "./NewReservation/ReservationVehicleAction";
import { ReservationStatusFilter } from "./ReservationStatusFilter/ReservationStatusFilter";
import { useGetVendorsQuery } from "features/vendors/vendorSlice";
import { ReservationView } from "./ReservationView/ReservationView";
import { ReservationEditAction } from "./NewReservation/ReservationEditAction";
import { VehicleLinks } from './VehicleLinks/VehicleLinks';

const ReservationPage = ({ }) => {

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: reservations, error: reservationsError, isLoading: isLoadingReservations } = useGetAllReservationsQuery();
    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetVendorsQuery();

    reservations = reservations || [];
    vendors = vendors || [];

    const accounts = clients?.flatMap(c => c.clientAccounts).filter(c => c.isDefault == false);
    const { searchVal, filters } = useSelector(state => state.reservationsFilters);
    const [sortOptions, sortData] = useSortOptions();

    const reservationsLookup = useMemo(() => {
        return arrayToObject(reservations);
    }, [reservations]);

    const dispatch = useDispatch();

    const [reservationToView, setReservationToView] = useState();

    useEffect(() => {
        if (reservationToView) {
            setReservationToView(reservationsLookup?.[reservationToView?.id])
        }
    }, [reservationsLookup]);

    const filterConfig = [
        { type: fieldTypes.TEXT, title: 'Client', key: 'clientId', values: clients },
        { type: fieldTypes.TEXT, title: 'Sub Client', key: 'clientAccountId', values: accounts },
        { type: fieldTypes.TEXT, title: 'Status', key: 'status', values: reservationStatusList },
        { type: fieldTypes.LOT, title: 'Pickup Location', key: 'pickupLocationId', values: lots },
        { type: fieldTypes.LOT, title: 'Dropoff Location', key: 'deliveryLocationId', values: lots },
        { type: fieldTypes.TEXT, title: 'Carrier', key: 'carrierId', values: vendors }
    ]

    const onDropFilter = (value, prop) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    };

    const onResetFilters = () => {
        dispatch(resetFilters());
    };

    const onSearch = (val) => {
        dispatch(setSearchVal(val));
    };

    const columns = [
        { name: "Reservation #", key: "id" },
        { name: "Client", key: "clientId", values: clients },
        { name: "Sub Client", key: "clientAccountId", values: accounts },
        { name: "Status", key: "status", values: reservationStatusList },
        { name: "Pickup Location", key: "pickupLocationId", values: lots },
        { name: "Dropoff Location", key: "deliveryLocationId", values: lots },
        { name: "Estimate Pick Up Window", key: "pickupDate", component: (row) => row.formattedPickupDate },
        { name: "Actual Pick Up Date", key: "actualPickUpDate", component: (row) => row.formattedActualPickUpDate },
        { name: "Pickup Time", key: "pickupTime" },
        { name: "Estimate Drop off Window", key: "deliveryDate", component: (row) => row.formattedDeliveryDate },
        { name: "Actual Drop Off Date", key: "actualDropOffDate", component: (row) => row.formattedActualDropOffDate },
        { name: "Insurance Expiry Date", key: "insuranceExpiryDate", component: (row) => row.formattedInsuranceExpiryDate },
        { name: "Dropoff Time", key: "deliveryTime" },
        { name: "Carrier", key: "carrierId", values: vendors },
        { name: "Asset", width: 200, key: 'ReservationVehicles', component: (row) => <VehicleLinks reservation={row} /> },
    ]

    const getName = (data, id) => data.find(l => l.id === id)?.name;

    const mapExportData = row => {
        const reservations = {};
        columns?.map(col => {
            if (col.name) {
                reservations[col.name] = row?.[col.key]
            }
        });
        reservations['Client'] = getName(clients, row?.clientId);
        reservations['Sub Client'] = getName(accounts, row?.clientAccountId);
        reservations['Status'] = getName(reservationStatusList, row?.status);
        reservations['Pickup Location'] = getName(lots, row?.pickupLocationId);
        reservations['Dropoff Location'] = getName(lots, row?.deliveryLocationId);
        reservations['Asset'] = row?.reservationVehicles?.map(a => a.vin || a.assetId)?.join(', ');
        reservations['Estimate Pick Up Window'] = row?.['formattedPickupDate'];
        reservations['Actual Pick Up Date'] = row?.['formattedActualPickUpDate'];
        reservations['Estimate Drop off Window'] = row?.['formattedDeliveryDate'];
        reservations['Actual Drop Off Date'] = row?.['formattedActualDropOffDate'];
        reservations['Insurance Expiry Date'] = row?.['formattedInsuranceExpiryDate'];
       

        return reservations;
    };
    const filteredReservations = FilterService.filter(filters, searchVal, reservations, filterConfig);

    const rowActions = (rowId) => {
        const row = reservationsLookup?.[rowId];
        return [
            {
                component: <ReservationOverviewAction reservation={row} />
            },
            {
                component: <ReservationEditAction reservation={row} />
            },
            // {
            //     component: <ReservationDocumentsAction reservation={row} />
            // },
            // {
            //     component: <ReservationVehicleAction reservation={row} />
            // }
        ]

    };
    const onRowClick = (row) => {
        setReservationToView(row?.rowData);
    }


    return (
        <PageComponent>
            <ReservationStatusFilter reservations={reservations} onSelectStatus={(status) => onDropFilter(status, 'status')} />
            <DataTable
                onRowClick={onRowClick}
                showActionsInMenu
                mapExportData={mapExportData}
                noItemsMessage={isLoadingReservations ? 'Loading...' : 'No reservations found'}
                tableId={userPreferencesComponentIds.RESERVATIONS_TABLE}
                allowColumnCustomization={true}
                withFilterPanel={true}
                dropDownsConfig={filterConfig}
                filters={filters}
                onDropFilter={onDropFilter}
                onSearch={onSearch}
                searchVal={searchVal}
                onResetFilters={onResetFilters}
                columns={columns}
                rows={orderBy(
                    filteredReservations,
                    sortOptions.columnToSort,
                    sortOptions.sortDirection
                )}
                rowIdentifier='id'
                onSort={sortData}
                sortDirection={sortOptions.sortDirection}
                columnToSort={sortOptions.columnToSort}
                title={'Reservations'}
                actions={rowActions}
                actionButtons={
                    <Grid container direction='row' spacing={1}>
                        <Grid item>
                            <AddReservation />
                        </Grid >
                    </Grid>
                }
            />
            {!!reservationToView && <ReservationView
                onClose={() => setReservationToView()}
                withDialog
                reservation={reservationToView}
            />}
        </PageComponent>
    )
};

export default ReservationPage;
