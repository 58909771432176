import { Grid } from "@material-ui/core";
import { ReservationOverviewAction } from "../NewReservation/ReservationOverviewAction";
import { reservationColors } from "../ReservationStatusFilter/ReservationStatusFilter";
import { ReservationViewStyle } from "./style";
import { ReservationEditAction } from "../NewReservation/ReservationEditAction";

export const ReservationSummary = ({ reservation }) => {
    const classes = ReservationViewStyle()

    const fields = [
        {
            value: `${reservation?.clientName} ${reservation?.subClientName ?? ''}`,
            label: 'Client',
        },
        {
            value: reservation?.carrierName ?? '-',
            label: 'DSP/Carrier'
        },
        {
            value: reservation?.pickupLotName ?? '-',
            label: 'Pickup at'
        },
        {
            value: reservation?.deliveryLotName ?? '-',
            label: 'Drop off at'
        },
        {
            value: reservation?.formattedPickupDate ?? '-',
            label: 'Pickup date'
        },
        {
            value: reservation?.formattedDeliveryDate ?? '-',
            label: 'Drop off date'
        },
        {
            value: reservation?.formattedActualPickUpDate ?? '-',
            label: 'Actual Pick Up Date'
        },
        {
            value: reservation?.formattedActualDropOffDate ?? '-',
            label: 'Actual Drop Off Date'
        },
       
        {
            value: reservation?.pickupTime ?? '-',
            label: 'Pickup time'
        },
        {
            value: reservation?.deliveryTime ?? '-',
            label: 'Delivery time'
        },
        {
            value: reservation?.formattedInsuranceExpiryDate ?? '-',
            label: 'Insurance Expiry Date'
        }
    ];

    return (<Grid container spacing={1} >
        <Grid item xs={12} alignItems="center" container spacing={1}
            className={classes.summaryStatus}
            style={{ borderColor: reservationColors?.[reservation.status], color: reservationColors?.[reservation.status] }}>
            <Grid item className={classes.summaryId}>#{reservation?.id}</Grid>
            <Grid item > {reservation?.statusName}</Grid>
        </Grid>
        {fields?.map((field, index) => <Grid container spacing={1} key={index} item xs={field?.size ?? 6}>
            <Grid item className={classes.summaryLabel}>{field?.label}</Grid>
            <Grid item className={classes.summaryField}>{field?.value}</Grid>
        </Grid>)}
        <Grid item justifyContent="flex-end" xs={12} style={{ marginLeft: '90%' }}>
            <ReservationEditAction reservation={reservation} buttonVariant="outlined" />
        </Grid>
    </Grid>)
}