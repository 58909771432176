import React from 'react';
import {Grid, Tooltip} from '@material-ui/core';
import usersPageStyle from './styles';
import {IconDownload} from "../../../../icons";
import ExportButton from "../../../../components/ExportButton/ExportButton";
import {permissionProfiles} from "../../../../components/Authorize/permissionProfiles";
import Authorize from "../../../../components/Authorize";
import BlackListUserDialog from "./blackListUserDialog";

const BlackListUsers = ({users, permission, clients, lots, roles, mapExportData, refetchUsers}) => {
    users = users || [];
    const classes = usersPageStyle();
    const usersFilter = users.filter(x => (x.isBlackList || x.deleted) && !x.isPurged);
    const totalUsers = usersFilter?.length ?? 0;
    return (
        <>

            <Grid className={classes.cardWrapper} container direction="column">
                <div className={classes.actions}>
                    <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_BLACK_LIST_VIEW_ACCESS_TABLE}>
                        <BlackListUserDialog permission={permission} clients={clients} lots={lots} roles={roles}
                                             user={usersFilter}
                                             refetchUsers={refetchUsers}/>

                    </Authorize>
                    <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_BLACK_LIST_VIEW_ACCESS_EXPORT}>
                        <ExportButton
                            className={classes.exportButton}
                            btnStyle='text'
                            label=""
                            icon={<IconDownload/>}
                            fileLabel="Export"
                            data={usersFilter}
                            mapExportData={mapExportData}
                        />
                    </Authorize>
                </div>
                <Grid item>
                    <Tooltip title="Blacklisted Users cannot log into ParQ, but can be reactivated">
                        <div>
                            <Grid item className={classes.count} style={{fontWeight: 400}}>{totalUsers}</Grid>
                            <div className={classes.text} style={{fontWeight: 400}}>
                                Blacklisted Users
                            </div>
                        </div>
                    </Tooltip>
                </Grid>
            </Grid>
        </>
    );
};

export default BlackListUsers;
