import { Button, Grid, Paper } from "@material-ui/core";
import { selectIsClient, selectUserClientId } from "features/user/userSlice";
import JsPDF from 'jspdf';
import { useSelector } from "react-redux";
import { arrayToObject } from "utils/object-util";
import { ImageElement } from "./components/ImageElement";
import { QuestionElement } from "./components/QuestionElement";
import { TextElement } from "./components/TextElement";
import { FormOutputStyle } from "./style";
import { base64image } from "./test";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ShimmerLoader from "components/ShimmerLoader/ShimmerLoader";
import { useState } from "react";
import FormatService from "services/formatService";

const elementTypes = {
    Question: 1,
    Title: 2,
    SubTitle: 3,
    Image: 4
}

const FormOutput = ({ headerContent, loading, fileName = 'output.pdf', form, layout, answers, base64Images }) => {

    const classes = FormOutputStyle();
    const isClient = useSelector(selectIsClient);
    const currentClientId = useSelector(selectUserClientId);

    const [generating, setGenerating] = useState();



    const generatePDF = () => {

        setGenerating(true);
        const report = new JsPDF('portrait', 'px', 'letter');

        function addFooters(doc) {
            const pageCount = doc.internal.getNumberOfPages();
            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                let pageSize = doc.internal.pageSize;
                let pageHeight = pageSize.height
                    ? pageSize.height
                    : pageSize.getHeight();
                doc.text(
                    'Page ' + String(i) + ' / ' + String(pageCount),
                    (report.internal.pageSize.getWidth() / 2) - 10,
                    pageHeight - 8,
                );
            }
        }

        function addGeneratedBy(doc) {
            doc.setPage(0);
            doc.text(
                `Generated by Parq.ai on`,
                (doc.internal.pageSize.getWidth() - 80),
                10,
            );
            doc.text(
                `${FormatService.formatDateTime(new Date())}`,
                (doc.internal.pageSize.getWidth() - 80),
                18,
            );
        }


        report.html(document.querySelector('#report'), {
            callback: function (doc) {
                doc.setFontSize(8);
                addGeneratedBy(doc);
                addFooters(doc);
                report.save(fileName);
                setGenerating(false);
            },
            html2canvas: {
                onrendered: function (canvas) {
                }
            },
            autoPaging: 'text',
            width: 500,
            windowWidth: 1200
        });
    }

    const allQuestiosn = form?.sections?.flatMap(section => section.questions);
    const questionLookup = arrayToObject(allQuestiosn, 'id');
    const answerLookup = arrayToObject(answers, 'questionId');
    const base64ImagesLookup = arrayToObject(base64Images, 'id');

    const getElementComponent = (element) => {
        switch (element.elementType) {
            case elementTypes.Question:
                const q = questionLookup[element.value];
                const a = answerLookup[element.value];
                return <QuestionElement element={element} question={q} answer={a} base64ImagesLookup={base64ImagesLookup} />
            case elementTypes.Image:
                return <ImageElement height={100} value={`data:image/png;base64,${element?.image}`} />;
            case elementTypes.Title:
                return <TextElement parse={false} value={element?.value} fontSize={'1.5em'} />;
            case elementTypes.SubTitle:
                return <TextElement parse={false} value={element?.value} />
            default:
                break;
        }
    }

    return (
        <div className={classes.reportContainer}>
            <Paper style={{ marginTop: '1em' }}>
                <div className={classes.exportableReport} id='report' >
                    {loading ?
                        <ShimmerLoader count={5} height="100" /> :
                        <>
                            {/* <div className={classes.generated}>Generated by Parq.ai on <br /> {FormatService.formatDateTimenew Date())}</div> */}
                            <div style={{ background: '#efefef' }}>{headerContent}</div>
                            {
                                layout?.sections?.map((section, index) => <>
                                    <Grid container style={{ border: '1px solid #efefef', padding: '1em' }}>
                                        {section.columns?.map(col => <Grid
                                            item
                                            xs={12 / section.columns.length}>
                                            {col.elements?.map(element => <div style={{ pageBreakInside: 'avoid' }}>
                                                <div className={classes.elementContainer}>
                                                    {getElementComponent(element)}
                                                </div>
                                            </div>)}
                                        </Grid>)}
                                    </Grid>
                                    <div style={{ pageBreakAfter: 'always' }}></div>
                                </>)
                            }
                        </>}
                </div >
            </Paper>
            {!loading && <Paper className={classes.reportActions} >
                <Button disabled={generating} startIcon={<SaveAltIcon />} variant="outlined" onClick={generatePDF} type="button">
                    {generating ? 'Generating PDF...' : 'Save As PDF'}
                </Button>
            </Paper>}
        </div>
    )
}
export default FormOutput;