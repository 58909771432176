import React, {useState} from 'react';
import {Grid, Select, MenuItem, Tooltip} from '@material-ui/core';
import usersPageStyle from './styles';
import {IconDownload} from "../../../../icons";
import ExportButton from "../../../../components/ExportButton/ExportButton";
import InactiveUserListDialog from "./inactiveUserListDialog";
import {permissionProfiles} from "../../../../components/Authorize/permissionProfiles";
import Authorize from "../../../../components/Authorize";

const InactiveUsers = ({users, permission, clients, lots, roles, mapExportData, refetchUsers}) => {
    const classes = usersPageStyle();
    const [dropdownValue, setDropdownValue] = useState(3);
    const filterUsersByLastLoginMonths = (users, months) => {
        return users.filter(user => {
            const diff = user.lastLoginMonthsDifference;
            if (months === 1) return diff >= 1;
            if (months === 3) return diff >= 3;
            if (months === 6) return diff >= 6;
            if (months === 12) return diff >= 12;
            return false;
        });
    };

    users = users?.filter(user => user.isInactiveUser && !user.isPurged && !user.isBlackList) || [];
    const usersFilter = filterUsersByLastLoginMonths(users, dropdownValue);
    const totalUsers = usersFilter?.length ?? 0;

    const handleDropdownChange = (event) => {
        setDropdownValue(event.target.value);
    };

    return (
        <>

            <Grid className={classes.cardWrapper} container direction="column">
                <div className={classes.actions}>
                    <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_INACTIVE_VIEW_ACCESS_TABLE}>
                        <Select
                            labelId="select-label"
                            value={dropdownValue}
                            onChange={handleDropdownChange}>
                            <MenuItem value={1}>1 Months</MenuItem>
                            <MenuItem value={3}>3 Months</MenuItem>
                            <MenuItem value={6}>6 Months</MenuItem>
                            <MenuItem value={12}>12+ Months</MenuItem>
                        </Select>
                        <InactiveUserListDialog permission={permission} clients={clients} lots={lots} roles={roles}
                                                user={usersFilter}
                                                refetchUsers={refetchUsers}/>

                    </Authorize>
                    <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_INACTIVE_VIEW_ACCESS_EXPORT}>
                        <ExportButton
                            className={classes.exportButton}
                            btnStyle='text'
                            label=""
                            icon={<IconDownload/>}
                            fileLabel="Export"
                            data={usersFilter}
                            mapExportData={mapExportData}
                        />
                    </Authorize>
                </div>
                <Grid item>
                    <Tooltip
                        title="A user is inactive if they have not logged into ParQ for the selected number of Months">
                        <div>
                            <Grid item className={classes.count} style={{fontWeight: 400}}>{totalUsers}</Grid>
                            <div className={classes.text} style={{fontWeight: 400}}>
                                Inactive Users
                            </div>
                        </div>
                    </Tooltip>
                </Grid>

            </Grid>
        </>
    );
};

export default InactiveUsers;
