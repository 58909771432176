import React from 'react';
import {Grid, Tooltip} from '@material-ui/core';
import usersPageStyle from './styles';
import {IconDownload} from "../../../../icons";
import ExportButton from "../../../../components/ExportButton/ExportButton";
import {permissionProfiles} from "../../../../components/Authorize/permissionProfiles";
import Authorize from "../../../../components/Authorize";
import PurgeUserListDialog from "./purgeUserListDialog";

const PurgeUsers = ({users, permission, clients, lots, roles, mapExportData, refetchUsers}) => {
    users = users || [];
    const classes = usersPageStyle();
    const usersFilter = users.filter(x => x.isPurged);
    const totalUsers = usersFilter?.length ?? 0;
    return (
        <>

            <Grid className={classes.cardWrapper} container direction="column">
                <div className={classes.actions}>
                    <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_PURGE_VIEW_ACCESS_TABLE}>
                        <PurgeUserListDialog permission={permission} clients={clients} lots={lots} roles={roles}
                                             user={usersFilter}
                                             refetchUsers={refetchUsers}/>

                    </Authorize>
                    <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_PURGE_VIEW_ACCESS_EXPORT}>
                        <ExportButton
                            className={classes.exportButton}
                            btnStyle='text'
                            label=""
                            icon={<IconDownload/>}
                            fileLabel="Export"
                            data={usersFilter}
                            mapExportData={mapExportData}
                        />
                    </Authorize>
                </div>
                <Grid item>
                    <Tooltip
                        title="Purged Users have been completely deleted from ParQ, only their user-names remain for logging purposes">
                        <div>
                            <Grid item className={classes.count} style={{fontWeight: 400}}>{totalUsers}</Grid>
                            <div className={classes.text} style={{fontWeight: 400}}>
                                Purged Users
                            </div>
                        </div>
                    </Tooltip>
                </Grid>
            </Grid>
        </>
    );
};

export default PurgeUsers;
