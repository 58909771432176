import { Button, Dialog, DialogContent } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useState } from "react";
import ReservationForm from "./Components/ReservationForm/ReservationForm";
import { ReservationView } from "../ReservationView/ReservationView";

export const ReservationOverviewAction = ({ reservation, buttonVariant }) => {
    const [open, setOpen] = useState();

    return (
        <>
            <Authorize profile={permissionProfiles.RESERVATIONS.VIEW_TABLE}>
                <Button
                    color='primary'
                    variant={buttonVariant}
                    onClick={() => setOpen(true)}
                >
                    Overview
                </Button>
            </Authorize>
            {open && <ReservationView reservation={reservation} onClose={() => setOpen(false)} />}
        </>)
}